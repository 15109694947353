import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouteReuseStrategy,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root',
})
export class IsLoggedInGuard implements CanActivate {
  constructor(public dataService: DataService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.dataService.profile = JSON.parse(
      window.localStorage.getItem(this.dataService.storeKey)
    );

    if (route.routeConfig.path == 'login') {
      if (this.dataService.profile) {
        this.router.navigate(['/tabs']);

        // TO-DO
        // SYNC PROFILE WITH SERVER.
        // 1. DATA CHANGES
        // 2. TOKEN EXPIRATION
        return false;
      } else {
        return true;
      }
    } else {
      if (this.dataService.profile) return true;
      else {
        // clear the local storage
        window.localStorage.clear();
        this.router.navigate(['/login']);

        return false;
      }
    }
  }
}
