import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { LoginComponent } from './login/login.component';
// import { RegisterComponent } from './register/register.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    canActivate: [IsLoggedInGuard],
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterPageModule),
  },

  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'own-profile/:accountId',
    canActivate: [IsLoggedInGuard],
    loadChildren: () =>
      import('./own-profile/own-profile.module').then(
        (m) => m.OwnProfilePageModule
      ),
  },  {
    path: 'wallet',
    loadChildren: () => import('./wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'blog-details',
    loadChildren: () => import('./blog-details/blog-details.module').then( m => m.BlogDetailsPageModule)
  },
  {
    path: 'add-profile-image',
    loadChildren: () => import('./add-profile-image/add-profile-image.module').then( m => m.AddProfileImagePageModule)
  },
  {
    path: 'select-district',
    loadChildren: () => import('./select-district/select-district.module').then( m => m.SelectDistrictPageModule)
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
