import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlertController,
  LoadingController,
  NavController,
} from '@ionic/angular';

declare const swal: any;
@Injectable({
  providedIn: 'root',
})
export class DataService {
  educationFields = [
    {
      name: 'बारावी पर्यंत / Upto 12th',
      value: '12',
    },
    {
      name: 'Medical Related ( MBBS , BD, BAMS, BHMS, etc. )',
      value: 'medical',
    },
    {
      name: 'Engineering Related ( BE, B.Tech, ME, M.Tech, etc. )',
      value: 'engineer',
    },
    {
      name: 'Other Graduation ( BA, BSC, BCOM, BBA, BCA, etc. )',
      value: 'graduation',
    },
    {
      name: 'Post Graduation ( MA, MSC, MCOM,MBA, MCA,etc. ) ',
      value: 'postgraduation',
    },
    {
      name: 'Diploma ( ITI, POLY, etc. ) ',
      value: 'diploma',
    },
    {
      name: 'इतर शिक्षण / Other',
      value: 'other',
    },
  ];
  selectedDistrict = {
    id: undefined,
    district: ""
  }
  auth = {
    phone: '',
    // phone: '8605650087',
    otp: '',
    otpSent: false,
    // otpSent: true,
  };
  home = {
    vadhuProfiles: [],
    varProfiles: [],
  };
  // points = 2;
  regions = [];

  shareAsked = false;
  appVersion = '0.0.0';
  ownProfile = undefined;
  // firstTimeOpen = true;
  // matrimonialFilter = {
  //   vadhuvar: undefined,
  // };
  youtubeVideos = [];
  deploymentOnWeb = false;
  bulkProfiles = [];

  // eventEmitters = {
  // logout: new EventEmitter(),
  // changePassword: new EventEmitter(),
  // update: new EventEmitter(),
  // };

  updatePageEvent = new EventEmitter();
  //IMPORANTANT
  // url = "https://cluster.webmediaindia.com/";

  mainDomainUrl = 'https://lagnaakshada.com/';
  appDomainUrl = 'https://app.lagnaakshada.com/';
  imageKitUrl = 'https://ik.imagekit.io/izkvcmltyhe/';
  imageKitUrlS3 = 'https://ik.imagekit.io/izkvcmltyhe/forS3/';
  s3BaseUrl = 'https://lagnaakshada-assets.s3.ap-south-1.amazonaws.com/';
  url = this.mainDomainUrl;
  baseStrapiUrl = 'https://strapi.lagnaakshada.com';
  strapiUrl = this.baseStrapiUrl + '/';
  storeKey = 'laProfile';
  isLoading = false;

  // SET DEFAULT
  castes = [
    {
      caste: 'Maratha',
    },
  ];
  matrimonialprofileId;

  role = '';
  profile;
  districts = [];
  cities = [];
  selectedDistrictCities = [];
  loader;
  phone;
  sliders = [];
  showLoaders = true;
  matrimonial = {
    amount: undefined,
  };

  getUserData() {
    this.http
      .get(this.url + 'auth/userDetails/' + this.profile.id)
      .subscribe((data: any) => {
        if (data.status) this.profile = data.data;
      });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    public http: HttpClient,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public router: Router
  ) {
    // TO-DO
    // ADD REDIRECTION TO APP.LA FROM LA.COM  IF WIDTH IS LESSS THAN HEIGH IN LANDING PAGE
    this.syncWithLocalStorageProfile();
    // this.baseStrapiUrl = 'http://localhost:1337';
    // this.strapiUrl = this.baseStrapiUrl + '/';
    // this.baseStrapiUrl = 'http://192.168.1.24:1337';
    // this.strapiUrl = this.baseStrapiUrl + '/';
    this.getDistricts();
    // this.getSliders();
    // this.getCastes();
    // this.getRegions();
  }

  isAdminFnc() {
    if (this.profile)
      if (
        this.profile.id == 8 ||
        this.profile.id == 2998 ||
        this.profile.id == 23
      )
        return true;
    return false;
  }
  getRegions() {
    this.http.get(this.strapiUrl + 'regions').subscribe(
      (data: any) => {
        this.regions = data;
      },
      (err) => {
        alert('Error getting Regions. Contact Admin');
      }
    );
  }
  getCastes() {
    this.http.get(this.strapiUrl + 'castes').subscribe((data: any) => {
      this.castes = data;
      this.castes = [this.castes[0]];
      // this.castes.slice(0, 1);
      // console.log("sliced caste", this.castes);
    });
  }

  viewImage(name, extraParamAddition = '', source = 'server') {
    if (source == 'server') {
      window.open(this.imageKitUrl + name + '/' + extraParamAddition, '_blank');
    } else if (source == 's3') {
      window.open(
        this.imageKitUrlS3 +
        name.replace(
          'https://lagnaakshada.s3.ap-south-1.amazonaws.com/',
          ''
        ) +
        '/' +
        extraParamAddition,
        '_blank'
      );
    }
  }

  supportClicked(navCtrl) {
    navCtrl.push('SupportPage');
  }

  whatsapp(phoneNumber) {
    window.open(`https://wa.me/${phoneNumber}`, '_blank');
  }
  getSliders() {
    this.sliders = [];
    this.http.get(this.url + 'slider').subscribe((data: any) => {
      console.log('GET SLIDER ', data);

      this.sliders = data.data;
    });
  }
  showSwal(message, type, submessage = '') {
    swal(message, submessage, type);
  }
  async present(content = 'Loading Data...') {
    this.isLoading = true;
    return await this.loadingCtrl
      .create({
        message: content,
        // duration: 5000,
      })
      .then((a) => {
        a.present().then(() => {
          console.log('presented');
          if (!this.isLoading) {
            a.dismiss().then(() => console.log('abort presenting'));
          }
        });
      });
  }

  async dismiss() {
    try {
      this.isLoading = false;
      return await this.loadingCtrl
        .dismiss()
        .then(() => console.log('dismissed'));
    } catch (error) { }
  }
  startLoaderNotUsing(content = 'Loading Data...', loaderInstance = undefined) {
    // if (loaderInstance) {
    //   loaderInstance =  this.loadingCtrl.create({
    //     message: content,
    //   });
    //   loaderInstance.present();
    // } else {
    //   this.loader = await this.loadingCtrl.create({
    //     message: content,
    //   });
    //   this.loader.present();
    // }
  }

  stopLoaderNotUsing(loaderInstance = undefined) {
    // await this.loadingCtrl.dismiss();
    // if (loaderInstance) {
    //   loaderInstance.dismiss();
    // } else {
    //   this.loader.dismiss();
    // }
  }
  getDistricts() {
    this.http.get(this.url + 'district').subscribe((data: any) => {
      console.log('GET DISTRICT ', data);

      if (!data.status) {
        this.showSwal(data.message, ' error');
        return;
      }

      this.districts = data.data;
    });
  }

  updateWalletDetails(showLoader = false) {
    if (showLoader) this.present('Checking Points...');
    if (this.profile.id)
      this.http.get(this.url + 'account/' + this.profile.id).subscribe(
        (data: any) => {
          this.dismiss();
          if (data.result.points) this.profile.points = +data.result.points;
        },
        (err) => {
          alert('Connection Error');
          this.dismiss();
        }
      );
  }
  updateProfile() {
    this.present();
    this.http.get(this.strapiUrl + 'accounts/' + this.profile.id).subscribe(
      (data) => {
        this.profile = data;
      },
      (err) => {
        alert('Connection Error');
        this.logout();
      }
    );
  }

  checkMatrimonialProfileIfExists() {
    console.log('this.dataService.profile', this.profile);
    setTimeout(() => {
      console.log('this.dataService.profile', this.profile);
    }, 1000);

    // this.dataService.startLoader();
    if (this.profile.id)
      this.http
        .get(this.strapiUrl + 'marraige-profiles', {
          params: {
            accountID: this.profile.id + '',
          },
        })
        .subscribe(
          (data: any) => {
            if (data.length) {
              this.matrimonialprofileId = data[0].id;
            }
          },
          (err) => {
            alert('Connection Error');
          }
        );
  }
  showConnectionError(err) {
    this.dismiss();
    this.showSwal('Connection Error', 'error');
    console.log(err);
  }
  parseImageUrl(name, extraParamAddition = '', source = 'server') {
    if (source == 'server') {
      return this.imageKitUrl + name + '/' + extraParamAddition;
    } else if (source == 's3') {
      console.log(
        this.imageKitUrlS3 +
        name.replace(
          'https://lagnaakshada.s3.ap-south-1.amazonaws.com/',
          ''
        ) +
        '/' +
        extraParamAddition
      );

      // remove https://lagnaakshada.s3.ap-south-1.amazonaws.com/
      return (
        this.imageKitUrlS3 +
        name.replace('https://lagnaakshada.s3.ap-south-1.amazonaws.com/', '') +
        '/' +
        extraParamAddition
      );
    }
  }
  syncWithLocalStorageProfile() {
    try {
      if (window.localStorage.getItem(this.storeKey)) {
        this.profile = JSON.parse(window.localStorage.getItem(this.storeKey));
        this.http.get(this.strapiUrl + 'accounts/' + this.profile.id).subscribe(
          (data: any) => {
            if (data) {
              this.profile = data;
              window.localStorage.setItem(this.storeKey, JSON.stringify(data));
            } else {
              this.logout();
            }
          },
          (err) => {
            this.logout();
          }
        );
      }
    } catch (error) {
      this.logout();
    }
  }

  logout() {
    window.localStorage.clear();
    this.auth.otp = undefined;
    this.auth.otpSent = false;
    this.auth.phone = undefined;

    this.profile = undefined;
    this.router.navigate(['/login']);
  }
  dataConversion(name) {
    switch (name) {
      case 'var':
        return 'वर';
      case 'vadhu':
        return 'वधू';
      case 'first':
        return 'प्रथमविवाह';
      case 'second':
        return 'दुसरा विवाह';
      case 'government':
        return 'सरकारी नोकरी';
      case 'private':
        return 'खाजगी नोकरी';
      case 'farmer':
        return 'शेती';
      case 'farming':
        return 'शेती';
      case 'shop':
        return 'स्वतःचे दुकान';
      case 'business':
        return 'व्यवसाय';
      case 'widow':
        return 'विधवा';
      case 'widower':
        return 'विधुर';
      case 'divorced':
        return 'घटस्फोटीत';
      case 'independent':
        return 'स्वतंत्र';

      case 'other':
        return 'इतर काही';
      case 'yes':
        return 'होय';
      case 'no':
        return 'नाही';
      case 'other':
        return 'इतर काही';
      case 'yes':
        return 'होय';
      case 'no':
        return 'नाही';
      case '12':
        return 'बारावी पर्यंत / Upto 12th';
      case 'medical':
        return 'Medical Related ( MBBS , BD, BAMS, BHMS, etc. )';
      case 'engineer':
        return 'Engineering Related ( BE, B.Tech, ME, M.Tech, etc. )';
      case 'graduation':
        return 'Other Graduation ( BA, BSC, BCOM, BBA, BCA, etc. )';
      case 'postgraduation':
        return 'Post Graduation ( MA, MSC, MCOM,MBA, MCA,etc. )';
      case 'diploma':
        return 'Diploma ( ITI, POLY, etc. )';
      case 'government':
        return 'Government (सरकारी नोकरी)';
      case 'private':
        return 'Private (खाजगी नोकरी)';
      case 'farmer':
        return 'Farming (शेती)';
      case 'business':
        return 'Business (व्यवसाय)';
      case 'student':
        return 'शिकत आहे';
      case 'houseWork':
        return 'घर काम';
      case 'notWorking':
        return 'Not Working (काम करत नाही)';

      case 'normal':
        return 'सर्वसाधारण';
      case 'medium':
        return 'मध्यम';
      case 'rich':
        return 'श्रीमंत';
      case 'superRich':
        return 'अतिश्रीमंत';
      case 'rent':
        return 'भाड्याचे';
      case 'own':
        return 'स्वतःचे';

      default:
        return name;
    }
  }

  convertCasteToMarathi(caste) {
    switch (caste) {
      case 'Maratha':
        return 'मराठा';

      default:
        return caste;
    }
  }
  isWorkingLocally() {
    return this.url == 'http://localhost:8080/' ? true : false;
  }

  convertToHeight(number) {
    let feet = 0;
    let inch = 0;

    if (number.length == 3) {
      feet = number[0];
      inch = number.slice(1, 3);
      //remove 0 from inch
    }

    return `${feet} feet ${inch} in `;
  }
  downloadApp() {
    window.open(
      `https://play.google.com/store/apps/details?id=com.webmediaindia.cluster&hl=en_IN&gl=US`,
      '_blank'
    );
  }

  goToLogin(navCtrl: NavController) {
    //IF THIS IS THE WEB THEN GO TO PLAY STORE
    if (this.deploymentOnWeb) {
      return this.downloadApp();
    }

    this.showSwal(
      'लॉगिन करा',
      '',
      'आपला पासवर्ड/ ओटीपी टाकून पुन्हा लॉगिन करा'
    );
    this.logout();
    // this.eventEmitters.logout.emit();
  }
  shareMatrimonialProfile(data) {
    // let textContent = encodeURI(
    //   `*${data.fullName}*\n\nजात : ${data.caste} \nआयडी क्रं : ${data.id} \nशिक्षण : ${data.educationName}\nपत्ता : ${data.village}\nव्यवसाय: ${data.canOccupation}\nजन्म दिनांक: ${data.canBirthDate}\n\n*अधिक माहितीसाठी एप डाउनलोड करा, शेअर करून कॅश रिवार्ड मिळवा..*\n https://play.google.com/store/apps/details?id=com.webmediaindia.cluster`
    // );
    // let textContent = `*${data.fullName}*\n\nजात : ${data.caste} \nआयडी क्रं : ${data.id} \nशिक्षण : ${data.educationName}\nपत्ता : ${data.village}\nव्यवसाय: ${data.canOccupation}\nजन्म दिनांक: ${data.canBirthDate}\n\n*अधिक माहितीसाठी एप डाउनलोड करा, शेअर करून कॅश रिवार्ड मिळवा..*\n`;
    // console.log(textContent);
    // this.socialShare(
    //   textContent,
    //   this.url + "uploads/image/" + data.personalImageId + ".jpg",
    //   "https://play.google.com/store/apps/details?id=com.webmediaindia.cluster"
    // );
    // window.open(`https://wa.me/?text=${textContent}`, '_blank');
  }
  shareApp() {
    // let textContent;
    // if (this.profile)
    //   textContent = encodeURI(
    //     `लग्न अक्षदा\n*सेवा भाव म्हणुन पुर्णपणे मोफत* ॲप डाउन लोड करुन  हा *रेफर ID : ${this.profile.id}* टाका\nअसंख्य स्थळे पहा व नोंदवा \n https://play.google.com/store/apps/details?id=com.webmediaindia.cluster`
    //   );
    // else
    //   textContent = encodeURI(
    //     `लग्न अक्षदा\n*सेवा भाव म्हणुन पुर्णपणे मोफत* ॲप आहे.\nअसंख्य स्थळे पहा व नोंदवा \n https://play.google.com/store/apps/details?id=com.webmediaindia.cluster`
    //   );
    // let textContent = encodeURI(
    //   `Downlaod लग्न अक्षदा App and user my referrer ID : ${this.profile.id} \n https://play.google.com/store/apps/details?id=com.webmediaindia.cluster`
    // );
    // console.log(textContent);
    // window.open(`https://wa.me/?text=${textContent}`, '_blank');
    // this.trackEvent("Share App", {
    //   profile: `${this.profile.id} ${this.profile.fullName}`,
    // });
  }
  clearAllBulkProfiles() {
    this.bulkProfiles = [];
  }
  socialShare(message, image = '', url = '') { }
  socialShareWhatsapp(receiver, message, image, url) { }
  // shareViaWhatsapp2(message, image = "", url = "") {
  //   this.socialSharing
  //     .shareViaWhatsApp(message, image, url)
  //     .then((data) => {
  //       console.log(data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }
  timeSince(creationDateString) {
    try {
      let creationDate: any = new Date(creationDateString);
      let today: any = new Date();
      var seconds = Math.floor((today - creationDate) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + ' years';
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + ' months';
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + ' days';
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + ' hours';
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + ' minutes';
      }
      return Math.floor(seconds) + ' seconds';
    } catch (error) {
      return 'Error';
    }
  }
  datediff(firstDateString, second: any = new Date()) {
    let first: any = new Date(firstDateString);
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  parseArrayofString(data) {
    try {
      let arrayData = JSON.parse(data);
      let parsedString = '';
      arrayData.forEach((element) => {
        parsedString += this.dataConversion(element) + ' , ';
      });
      return parsedString;
    } catch (error) {
      return data;
    }
  }

  checkLogin(navCtrl) {
    if (!this.profile) {
      this.goToLogin(navCtrl);
    }
  }

  goToWalletPage(navCtrl: NavController) {
    // if (this.points < requiredBalance) {
    this.showSwal('Low Points', '', '');
    // navCtrl.push('WalletPage');
    // }
  }
  modifyWallet(value) {
    this.profile.points = +this.profile.points + +value;

    this.http
      .put(this.strapiUrl + 'accounts/' + this.profile.id, {
        points: this.profile.points + '',
      })
      .subscribe((data) => { });
  }
  donationsToCert(donation) {
    if (donation < 50) return 'https://ik.imagekit.io/izkvcmltyhe/silver.png';
    else if (donation < 100)
      return 'https://ik.imagekit.io/izkvcmltyhe/gold.png?updatedAt=1641774987256';
    else if (donation < 150)
      return 'https://ik.imagekit.io/izkvcmltyhe/diamond?updatedAt=1641774905904';
    else
      return 'https://ik.imagekit.io/izkvcmltyhe/goldenCup.png?updatedAt=1641774961148';
  }
  donationsToClass(donation) {
    if (donation < 50) return 'bg-white';
    else if (donation < 100) return 'bg-green-400';
    // else if (donation < 150)
    //   return "https://ik.imagekit.io/izkvcmltyhe/diamond?updatedAt=1641774905904";
    else return 'bg-yellow-400';
  }
  updateGeolocation() {
    if (!this.profile) return;
    // try {
    navigator.geolocation.getCurrentPosition((data) => {
      console.log('geolocation', data);
      this.http
        .put(this.strapiUrl + 'accounts/' + this.profile.id, {
          lat: data.coords.latitude + '',
          lng: data.coords.longitude + '',
        })
        .subscribe();
    });
    // } catch (error) {}
  }
}
