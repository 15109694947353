import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component } from '@angular/core';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  constructor(public http: HttpClient) {
    window.addEventListener('message', this.handleEvent, false);
  }
  ngAfterViewInit() {}

  handleEvent(event: any = { type: '', value: '' }) {
    if (event.data.test)
      alert(
        'handling in child...' + event.data.eventType + '..' + event.data.value
      );
  }
  eventHandlesNotUsingJustForCoding() {
    window.addEventListener('message', ({ data }) => {
      // alert('parent' + e.data);
      if (data.type == 'registerToken')
        alert('will register token' + data.value);
    });
  }
}
