import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { DataService } from '../services/data.service';
import { Md5 } from 'ts-md5/dist/md5';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  tempProfile;

  constructor(
    public navCtrl: NavController,
    public http: HttpClient,
    public dataService: DataService
  ) {
    // var url_string = window.location.href;
    // var url = new URL(url_string);
    // var c = url.searchParams.get("discover");
    // if (c) this.discover();
    // this.phone = "8605650087";
    // this.sendOTP();
    // alert(Md5.hashStr("123654"));
    // if (this.dataService.firstTimeOpen) {
    //   this.navCtrl.setRoot(TabsPage);
    // }
    // this.dataService.firstTimeOpen = false;
  }
  ngOnInit() {}
  resend() {
    this.dataService.showSwal('Otp Sent', 'success', '');
  }
  ionViewDidEnter() {}
  ionViewDidLoad() {
    console.log('ionViewDidLoad UserLoginPage');
  }
  login() {
    this.dataService.present();
    this.http
      .post(this.dataService.strapiUrl + 'accounts/phonecheck', {
        phone: this.dataService.auth.phone,
      })
      .subscribe(
        (data: any) => {
          this.dataService.dismiss();
          if (data.profile) {
            this.tempProfile = data.profile;
            this.dataService.auth.otpSent = true;
          }
        },
        (err) => {
          this.dataService.dismiss();
          alert('Connection Error');
        }
      );
  }
  verify() {
    // this.dataService.auth.otpSent = true;
    this.dataService.present('Checking...');
    this.http
      .post(this.dataService.strapiUrl + 'accounts/verify', {
        userId: this.tempProfile.id,
        otp: this.dataService.auth.otp,
        // phone: this.phone,
      })
      .subscribe(
        (data: any) => {
          this.dataService.dismiss();
          if (data.status) {
            this.dataService.profile = data.profile;
            localStorage.setItem(
              this.dataService.storeKey,
              JSON.stringify(data.profile)
            );

            if (!this.dataService.profile.fullName)
              this.navCtrl.navigateForward(['/profile']);
            else this.navCtrl.navigateForward(['/tabs']);
          } else {
            localStorage.clear();
          }
          // this.navCtrl.navigateForward(['/tabs']);
        },
        (err) => {
          this.dataService.dismiss();
          alert('Connection Error');
        }
      );
  }

  // discover() {

  //   this.navCtrl.push("MatrimonialInfoPage");
  // }

  whatsapp(phoneNumber) {
    window.open(`https://wa.me/${phoneNumber}`, '_blank');
  }
  changePhone() {
    // reset all

    this.dataService.auth.otpSent = false;
    this.dataService.auth.otp = undefined;
  }
  // register() {
  //   this.navCtrl.push("UserRegisterPage");
  // }
}
