import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IonInfiniteScroll,
  LoadingController,
  NavController,
} from '@ionic/angular';
import { DataService } from './data.service';
import qs from 'qs';
@Injectable({
  providedIn: 'root',
})
export class MatrimonialService {
  data = [];
  imageProfiles = [];
  searchedProfiles = [];
  educationFields = [
    {
      name: 'बारावी पर्यंत / Upto 12th',
      value: '12',
    },
    {
      name: 'Medical Related ( MBBS , BD, BAMS, BHMS, etc. )',
      value: 'medical',
    },
    {
      name: 'Engineering Related ( BE, B.Tech, ME, M.Tech, etc. )',
      value: 'engineer',
    },
    {
      name: 'Other Graduation ( BA, BSC, BCOM, BBA, BCA, etc. )',
      value: 'graduation',
    },
    {
      name: 'Post Graduation ( MA, MSC, MCOM,MBA, MCA,etc. ) ',
      value: 'postgraduation',
    },
    {
      name: 'Diploma ( ITI, POLY, etc. ) ',
      value: 'diploma',
    },
    {
      name: 'इतर शिक्षण / Other',
      value: 'other',
    },
  ];
  // selectedEducationCategoryIndex = 0;
  educationCategories = [
    {
      id: 15,
      category: 'General',
      status: 1,
      educations: [
        {
          id: 61,
          categoryId: 15,
          name: 'बारावी पर्यंत / Upto 12th',
          value: '12',
        },
        {
          id: 62,
          categoryId: 15,
          name: 'पदवी समकक्ष / Graduation',
          value: 'graduation',
        },
        {
          id: 63,
          categoryId: 15,
          name: 'पदयुत्तर समकक्ष / Post Graduation',
          value: 'postgraduation',
        },
        {
          id: 64,
          categoryId: 15,
          name: 'मेडिकल संबधी / Medical Related',
        },
        {
          id: 65,
          categoryId: 15,
          name: 'इंजिनिअर संबधी / Engineering Related',
        },
        {
          id: 66,
          categoryId: 15,
          name: 'इतर शिक्षण / Other',
        },
      ],
    },
  ];
  selectedEducationCategory = [];
  filter = {
    districtId: undefined,
    districtIds: [],
    marriageType: undefined,
    // marriageType: "second",
    caste: undefined,
    // vadhuvar: undefined,
    vadhuvar: 'vadhu',
    viewAll: undefined,
    lastProfileIndex: 99999999,
    findQuery: '',
    phoneFindQuery: '',

    selectedRegionId: undefined,
    selectedDistrictIndex: undefined,
    endpoint: undefined,
    adminApproved: true,
    // profileId:undefined
  };

  matrimonialProfiles: Array<any> = [];
  constructor(
    public http: HttpClient,
    public loadingCtrl: LoadingController,
    public dataService: DataService
  ) {
    // alert("sadf");
    // this.getEducationCategories();
  }

  resetData() {
    this.filter.findQuery = '';
    this.filter.lastProfileIndex = 99999999;
  }

  getMatrimonialProfiles(
    navCtrlInstance: NavController = undefined,
    clearPreviousProfiles = true,
    infiniteScroll: IonInfiniteScroll = undefined
  ) {
    if (clearPreviousProfiles) {
      this.matrimonialProfiles = [];
    }
    if (!infiniteScroll && !this.filter.findQuery) this.dataService.present();

    this.http
      .get(this.dataService.url + 'matrimonial/profile', {
        params: {
          districtId: this.filter.districtId,
          marriageType: this.filter.marriageType,
          caste: this.filter.caste,
          vadhuVar: this.filter.vadhuvar,
          viewAll: this.filter.viewAll ? '1' : '0',
          lastProfileIndex: this.filter.lastProfileIndex + '',
          findQuery: this.filter.findQuery,
          adminApproved: this.filter.adminApproved ? '1' : '0',

          // adminApproved: viewAll ? "1" : "0",
        },
      })
      .subscribe(
        (data: any) => {
          console.log('GET MATRIMONIAL PROFILE ', data);
          this.stopScroll(infiniteScroll);
          if (!infiniteScroll && !this.filter.findQuery)
            this.dataService.dismiss();

          if (!data.status) {
            this.dataService.showSwal(data.message, 'error');
            return;
          }

          if (!data.data.length && !this.filter.findQuery) {
            if (navCtrlInstance)
              this.dataService.showSwal('No Profiles Found !', 'warning');
            return;
          }
          const next10Data = data.data;
          console.log('before shuffle', data);

          // next10Data = this.shuffle(next10Data);
          this.matrimonialProfiles.push(...next10Data);

          // if (navCtrlInstance) navCtrlInstance.push('MatrimonialListPage');
        },
        (err) => {
          alert('Connection Error');
          this.stopScroll(infiniteScroll);
          // if (infiniteScroll) infiniteScroll.complete();
          if (!infiniteScroll && !this.filter.findQuery)
            this.dataService.dismiss();
        }
      );
  }

  stopScroll(infiniteScroll: IonInfiniteScroll) {
    try {
      infiniteScroll.complete();
    } catch (error) { }
  }

  getImageProfiles(infiniteScroll: any = undefined, clearPreviousProfiles = false) {


    // this.http.get(this.dataService.strapiUrl + 'image-profiles').subscribe((d: any) => {
    this.http.get(this.dataService.strapiUrl + 'image-profiles?_limit=10&_sort=id:desc').subscribe((d: any) => {
      this.imageProfiles = d
    })

  }
  getProfilesOld(infiniteScroll: any = undefined, clearPreviousProfiles = false) {
    if (infiniteScroll) console.log('infiniteScroll', infiniteScroll);

    let lastPositionNumber;
    if (clearPreviousProfiles) this.data = [];
    if (!this.data.length) lastPositionNumber = 999999999;
    else lastPositionNumber = this.data[this.data.length - 1].positionNumber;
    let lastId;
    if (!this.data.length) lastId = 999999999;
    else lastId = this.data[this.data.length - 1].id;

    console.log('lastPositionNumber', {
      lastPositionNumber,
    });

    this.http
      .post(this.dataService.strapiUrl + 'graphql', {
        // sort:"${
        //   this.filter.adminApproved ? 'positionNumber:desc' : 'id:desc'
        // }"
        query: `query{
          marraigeProfiles(
           limit:10,
          sort:"positionNumber:desc"

          where: {
            admin_approved:${this.filter.adminApproved ? 1 : 0}

            ${this.filter.marriageType
            ? 'marriageType:' + '"' + this.filter.marriageType + '"'
            : ''
          }
            vadhuVar:"${this.filter.vadhuvar}"

            positionNumber_lt:"${lastPositionNumber}"

            accountID:{

              id_gte:0,

              cityID:{
                id_gte:0,
                districtID:{

                  ${this.filter.districtIds.length
            ? 'id_in:' + '[' + this.filter.districtIds + ']'
            : ''
          }



                }
              }


            }



           }
         ){
            id
            positionNumber
            fullName
            rating
            caste
            otherCanEducation
            canOccupation
            created_at
            admin_approved
            vadhuVar
            personalImageId
            personalImageUrl
            accountID{
              id
              fullName
              cityID{
                city
                districtID{
                  district
                  regionId{
                    id
                    region
                  }
                }
              }
            }
         }
         }

`,
      })
      .subscribe(
        (data: any) => {
          this.data.push(...data.data.marraigeProfiles);

          if (infiniteScroll) infiniteScroll.target.complete();
          // = [...data.data.marraigeProfiles];
          // this.stopScroll(infiniteScroll);
        },
        (err) => {
          // this.stopScroll(infiniteScroll);
          alert('Connection Error');
        }
      );
  }
  shuffle2(array) {
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  shuffle() {
    this.dataService.present();
    this.http
      .get(this.dataService.strapiUrl + 'marraige-profiles/shuffle')
      .subscribe(
        (data) => {
          this.dataService.dismiss();
          this.dataService.showSwal('Done', 'success');
        },
        (er) => {
          alert('Error');
          this.dataService.dismiss();
        }
      );
  }

  getOwnProfile() {
    this.http
      .get(this.dataService.url + 'matrimonial/status', {
        params: { accountID: this.dataService.profile.id + '' },
      })
      .subscribe(
        (data: any) => {
          console.log('GET MATRIMONIAL STATUS ', data);

          if (!data.status) {
            this.dataService.showSwal(data.message, 'error');
            return;
          }
          this.dataService.matrimonial.amount = data.amount || 50;
          this.dataService.ownProfile = undefined;
          switch (data.profileStatus) {
            case 'profileRegistered':
              this.dataService.ownProfile = data.matrimonialRegistration;
              break;

            default:
              break;
          }
        },
        (err) => {
          alert('Connection Error');
        }
      );
  }
}
