import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
declare const swal: any;
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        console.log('EVENT', event);

        if (event.type === HttpEventType.Response) {
          console.log('response ', event.body);
          if (event.body.swal)
            swal(
              event.body.swal.title,
              event.body.swal.message || '-',
              event.body.swal.type
            );

          // swal(
          //   event.body.swal.title,
          //   event.body.swal.message,
          //   event.body.swal.type
          // );
        }
      })
    );
  }
}
